/*
|--------------------------------------------------------------------------
| Packages
|--------------------------------------------------------------------------
*/
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/*
|--------------------------------------------------------------------------
| Base
|--------------------------------------------------------------------------
*/
@import "resources/assets/sass/_base.scss";

/*
|--------------------------------------------------------------------------
| Sidebar
|--------------------------------------------------------------------------
*/
@import "resources/assets/sass/_sidebar.scss";
.admin_mandatory{
  color: red;
  font-size: 16px;
  font-family: 'ff-dax-web-pro', 'Myriad Pro', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  padding-left: 3px;
}
.modal-body{
  overflow: auto;
}
.login_form {
  background: url('../../../public/images/background.jpg');
  min-height: 100%;
  min-width: 100%;
  background-size: cover;
  position: fixed;
  background-position: center center;
}
.login_box{
  background-color: rgb(255,255,255);
  padding: 30px;
  margin-top: 10%;
}
.login_box h1{
  margin-top: -10px;
  text-align: left;
  margin-left: 15px;
  /*color:#fff;*/
}
/*.login_box label{
  color:#fff;
}*/
.navbar-inverse{
  background-color: #fff;
  border-color: #fff;
}
.awa-border {
  border-left: 5px solid rgba(237, 28, 36, 1);
  margin-left: -2.5px;
  width: 0 !important;
  min-width: 0 !important;
  margin-right: 12px;
}
.navbar-brand{
  color:#000 !important;
}
.navbar-brand:hover{
  color:#000 !important;
}
.footer{
  background-color: #fff;
}
.footer p{
  color: #000;
}
.logo{
  height: 350px;
}
.navbar-inverse .navbar-text{
 color: #000000;
}
.internal_doc {
  margin-right: 10px;
  padding: 5px;
  font-size: 14px;
  margin-left: 14px;
}
.csv-export{
  margin-top: 30px;
}
.table-responsive{
  font-size: 8pt;
}
.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus{
  color: #000;
}