html, body {
    margin: 0;
    padding: 0;
}

/*
|--------------------------------------------------------------------------
| Base YabSec
|--------------------------------------------------------------------------
*/

.app-wrapper {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    padding: 0px !important;
}

/*
|--------------------------------------------------------------------------
| Alert
|--------------------------------------------------------------------------
*/
.alert {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 24px;
    z-index: 10000;
}

/*
|--------------------------------------------------------------------------
| Main
|--------------------------------------------------------------------------
*/

.main {
    margin-bottom: 48px;
}

@media (min-width: 768px) {
    .main {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.main .page-header {
    margin-top: 0;
}

.index-table {
    margin-top: 12px;
    a {
        margin: 0 8px;
    }
}

/*
|--------------------------------------------------------------------------
| Footer
|--------------------------------------------------------------------------
*/

.footer {
    width: 100%;
    height: 50px;
    background-color: #222;
    color: #FFF;
}

.footer p {
    line-height: 50px;
    margin: 0 0 0 24px;
    padding: 0;
}
